export const VideoPlayer = (props) => {
    return <video
        className="banner-video"
        width="100%"
        height="100%"
        autoPlay={props.autoPlay || false}
        loop={props.loop || false}
        muted={props.muted || false}
        controls={props.controls}
        webkit-playsinline={'true'} playsInline
        disablePictureInPicture
        controlsList="nodownload noplaybackrate"
        poster={props.poster}
    >
        <source src={props.source} type="video/mp4" />
        Your browser does not support the video tag.
    </video>
}