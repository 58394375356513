import { useEffect, useState } from "react";
import logo from "../assets/images/realestate_images/logo.svg";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store";

export const NavbarContainer = (props) => {

    const navigate = useNavigate();
    const [shadow, setShadow] = useState(false);

    const toggleContactFormVisibilityCB = useStore((store) => store.toggleContactFormVisibility);
    const toggleContactUsPopupVisibilityCB = useStore((store) => store.toggleContactUsPopupVisibility);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setShadow(true);
        } else {
            setShadow(false);
        }
    };

    return (<div className={`realestate-navbar-wrapper ${shadow ? 'realestate-navbar-shadow' : ''}`}>
        <div className="realestate-navbar-container">
            <img className="realestate-navbar-logo" src={logo} alt="ampersand-logo"
                onClick={() => navigate('/')} />
            <div className="realestate-navbar-wrapper-items">
                <div className="call-to-action" onClick={() => toggleContactFormVisibilityCB(true)}>{props.actionBtnText}</div>
                <svg className="realestate-hamburger-menu" onClick={() => toggleContactUsPopupVisibilityCB(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                </svg>
            </div>
        </div>
    </div>)
}