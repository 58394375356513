import { useEffect, useRef, useState } from "react";
import { MENU_ITEMS } from "../common/constants";
import logo from "../assets/images/logo.svg";
import gsap from "gsap";
import ReactGA from "react-ga4";
import "../assets/css/navbar.css";

export const Navbar = () => {
    const refs = useRef(undefined);
    const [openMenuPopup, setOpenMenuPopup] = useState(false);

    useEffect(() => {
        // GSAP animation
        gsap.fromTo(".navbar-logo", { y: -100 }, { y: 0, duration: .2, delay: 0 });

        gsap.fromTo(".navbar-menu-item.about", { y: -100 }, {
            y: 0, duration: .25,
            ease: "slow(0.7,0.7,true)", delay: 1
        });
        gsap.fromTo(".navbar-menu-item.work", { y: -100 }, {
            y: 0, duration: .25,
            ease: "slow(0.7,0.7,true)", delay: 1.05
        });
        gsap.fromTo(".navbar-menu-item.capabilities", { y: -100 }, {
            y: 0, duration: .25,
            ease: "slow(0.7,0.7,true)", delay: 1.10
        });
        gsap.fromTo(".navbar-menu-item.culture", { y: -100 }, {
            y: 0, duration: .25,
            ease: "slow(0.7,0.7,true)", delay: 1.15
        });
        gsap.fromTo(".navbar-menu-item.contact", { y: -100 }, {
            y: 0, duration: .25,
            ease: "slow(0.7,0.7,true)", delay: 1.20
        });

    }, []);

    useEffect(() => {
        let hamBurgerRect = refs.hamBurger.getBoundingClientRect();
        if (openMenuPopup) {
            refs.hamBurgerOverlay.style.clipPath = `circle(135% at calc(${window.innerWidth}px - var(--sides-padding) - ${hamBurgerRect.width / 2}px) calc(${hamBurgerRect.top}px + ${hamBurgerRect.width / 2}px))`;
        } else {
            refs.hamBurgerOverlay.style.clipPath = `circle(17px at calc(${window.innerWidth}px - var(--sides-padding) - 6px - ${hamBurgerRect.width / 2}px) calc(${hamBurgerRect.top}px + ${hamBurgerRect.width / 2}px))`;
        }
    }, [refs.hamBurger, openMenuPopup]);

    const sendDataToGA = (category, action, label) => {
        ReactGA.event({
            category, action, label,
            // value: value, // optional, must be a number 
        });
    }

    const handleTimeout = (e, itemname) => {
        sendDataToGA('Menu Item', 'Menu Item clicks', itemname);
        e.preventDefault();
        setTimeout(() => {
            window.location.href = `#${itemname}`;
        }, 500);
    }

    return (<>
        <div className="navbar-holder">
            <img loading="lazy" className="navbar-logo" src={logo} alt="ampersand-logo" />
            <ul className="navbar-menu-items-holder">
                {
                    MENU_ITEMS.map((item, index) => {
                        return <li key={index} className={`navbar-menu-item ${item.name}`}>
                            <a href={'#' + item.name} onClick={() => sendDataToGA('Menu Item', 'Menu Item clicks', item.name)}>
                                <span>{item.name}</span>
                            </a>
                        </li>
                    })
                }
            </ul>
            <svg ref={elem => refs.hamBurger = elem} className={`navbar-hamburger-menu ${openMenuPopup ? 'active' : ''}`} onClick={() => {
                setOpenMenuPopup(ps => !ps);
            }} viewBox="0 0 100 100">
                <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path className="line line2" d="M 20,50 H 80" />
                <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
            </svg>
        </div>

        <div className={`mobile-menubar-popup-container ${openMenuPopup ? 'active' : ''}`}>
            <ul className="mobile-menubar-items-holder">
                {
                    MENU_ITEMS.map((item, index) => {
                        return (<li key={index} className={`navbar-menu-item ${item.name}`} onClick={() => setOpenMenuPopup(false)} >
                            <a href={'#' + item.name} onClick={(e) => handleTimeout(e, item.name)}>
                                <span>{item.name}</span>
                            </a>
                        </li>)
                    })
                }
            </ul>
        </div>
        <div ref={elem => refs.hamBurgerOverlay = elem} className="navbar-hamburger-menu-circular-overlay"></div>
    </>)
}