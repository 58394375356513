import { useEffect, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { useStore } from "../store";

export const UserForm = () => {

    const varstore = useRef(undefined);
    const form = useRef(undefined);
    const [inputNumber, setInputNumber] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const showContactFormVisibilityStatus = useStore((store) => store.showContactForm);
    const toggleContactFormVisibilityCB = useStore((store) => store.toggleContactFormVisibility);
    const closeAllPopupsCB = useStore((store) => store.closeAllPopups);

    useEffect(() => {
        if (isFormSubmitted) {
            toggleContactFormVisibilityCB(false);
        }
    }, [isFormSubmitted]);

    useEffect(() => {
        let timeout = undefined;
        if (!showContactFormVisibilityStatus && isFormSubmitted) {
            timeout = setTimeout(() => {
                setIsFormSubmitted(false);
                form.current.reset();
                closeAllPopupsCB();
            }, 3000);
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [showContactFormVisibilityStatus]);

    const validateInput = (value) => {
        const length = value.length;
        if (length >= 8 && length <= 10) {
            setErrorMessage('');
        } else {
            setErrorMessage('Invalid number');
        }
    };

    const numberInputNumber = (e) => {
        const { value } = e.target;
        setInputNumber(value);
        validateInput(value);
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (errorMessage) {
            return;
        }
        setIsFormSubmitted(true);

        const EMAIL_TEMPLATE_ID_ADMIN = 'template_h43hoyu';
        const EMAIL_TEMPLATE_ID_USER = 'template_xribbfq';
        const SERVICE_ID = 'service_0sotisi';
        const PUBLIC_KEY = 'tEljefcaf6Cv1ZwIb';

        await emailjs.sendForm(SERVICE_ID, EMAIL_TEMPLATE_ID_ADMIN,
            form.current, { publicKey: PUBLIC_KEY, })
            .then(() => {
                // notifyContactFormSubmitted();
                // console.log('SUCCESS SENT TO ADMIN!');
            }, (error) => {
                console.log('FAILED TO SENT TO ADMIN...', error.text);
            });

        await emailjs.sendForm(SERVICE_ID, EMAIL_TEMPLATE_ID_USER,
            form.current, { publicKey: PUBLIC_KEY, })
            .then(() => {
                // console.log('SUCCESS SENT TO USER!');
            }, (error) => {
                console.log('FAILED TO SENT TO USER...', error.text);
            });

        form.current.reset();
        setInputNumber('');
    }

    return (<>
        <form ref={form} className={`realestate-user-data-container ${showContactFormVisibilityStatus ? 'pop-in' : ''}`}
            onSubmit={onSubmitHandler}>
            <div className="user-data-container-heading">get in touch</div>
            <div className="user-data-container-desc">drop your coordinates here. we will get back to you in 24 hours!</div>
            <input ref={elem => varstore.name = elem} type="text" name="user_name" placeholder="name" required />
            <input ref={elem => varstore.email = elem} type="email" name="user_email" placeholder="email" required />
            <input ref={elem => varstore.mobileNum = elem} type="number" name="user_contact_number" placeholder="mobile no" value={inputNumber} onChange={numberInputNumber} required />
            {errorMessage && <span style={{ color: 'red', fontSize: '12px' }}>{errorMessage}</span>}

            <input ref={elem => varstore.company = elem} type="text" name="user_company" placeholder="company" required />
            <input ref={elem => varstore.designation = elem} type="text" name="user_designation" placeholder="designation" required />
            <button className="btn">send</button>
        </form>

        <div className={`user-form-done-submit ${(!showContactFormVisibilityStatus && isFormSubmitted) ? 'pop-in' : ''}`}>
            <span> thank you. </span>we will get in touch with you in 24 hours.
        </div>
    </>)
}