import React, { useEffect, useRef } from "react";
import loaderImg from "../assets/images/gifs/loader.gif";
import gsap from "gsap";
import "../assets/css/preloader.css";

export const Preloader = (props) => {

    const varstore = useRef(undefined);

    useEffect(() => {
        if (props.isBannerVideoLoaded && props.ispageLoaded) {
            varstore.overlayHolder.style.background = 'transparent';
            GSAP_animation();
            document.body.style.overflow = 'unset';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }, [props.isBannerVideoLoaded, props.ispageLoaded]);

    const GSAP_animation = () => {

        const t1 = gsap.timeline({ paused: false });
        const loaderCurtain = 0.8;

        t1.fromTo(varstore.path,
            { attr: { d: 'M0,1005S175,995,500,995s500,5,500,5V0H0Z', }, duration: loaderCurtain },
            { attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z', }, duration: loaderCurtain }
        )

        t1.to(varstore.path, { attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' }, duration: loaderCurtain, delay: -.6 });
        // gsap.to(".pre-loader-overlay", { display: 'none', delay: 1 });
        gsap.to(".pre-loader-overlay", { display: 'none', delay: 1 });
    }

    return (<div className={`pre-loader-overlay`}>
        <div ref={elem => varstore.overlayHolder = elem} className="pre-loader-overlay-holder">
            {
                !props.isBannerVideoLoaded || !props.ispageLoaded
                    ? <div className="pre-loader-animation-holder">
                        <img className="pre-loader-animation" src={loaderImg} alt="ampersand-pre-loader" />
                    </div>
                    : ''
            }
            <svg viewBox="0 0 1000 1000">
                <path ref={elem => varstore.path = elem} d="M0 2S175 1 500 1s500 1 500 1V0H0Z"></path>
                {/* <path ref={elem => varstore.path = elem} d="M0,1005S175,995,500,995s500,5,500,5V0H0Z"></path> */}
            </svg>
        </div>
    </div>)
}