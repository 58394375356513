import advertisingStaticIcon from "../assets/images/advertising.webp";
import advertisingGifIcon from "../assets/images/gifs/advertising.gif";
import corporateStaticIcon from "../assets/images/corporate.webp";
import corporateGifIcon from "../assets/images/gifs/corporate.gif";
import designStaticIcon from "../assets/images/design.webp";
import designGifIcon from "../assets/images/gifs/design.gif";
import retailStaticIcon from "../assets/images/retail.webp";
import retailGifIcon from "../assets/images/gifs/retail.gif";
import filmStaticIcon from "../assets/images/films.webp";
import filmGifIcon from "../assets/images/gifs/films.gif";
import digitalContentStaticIcon from "../assets/images/digital_content.webp";
import digitalContentGifIcon from "../assets/images/gifs/digital_content.gif";
import starBoxGifIcon from "../assets/images/gifs/star_box.gif";
import starBoxStaticIcon from "../assets/images/star_box.webp";
import peopleGifIcon from "../assets/images/gifs/people.gif";
import peopleStaticIcon from "../assets/images/people.webp";
import globeGifIcon from "../assets/images/gifs/globe.gif";
import globeStaticIcon from "../assets/images/globe.webp";
import threeStarGifIcon from "../assets/images/gifs/three_star.gif";
import threeStarStaticIcon from "../assets/images/three_star.webp";
import work_1 from "../assets/images/optimized/work_1.webp"
import work_2 from "../assets/images/optimized/work_2.webp"
import work_3 from "../assets/images/optimized/work_3.webp"
import work_4 from "../assets/images/optimized/work_4.webp"
import work_5 from "../assets/images/optimized/work_5.webp"
import work_6 from "../assets/images/optimized/work_6.webp"

import casagrand_logo from "../assets/images/realestate_images/casagrand_logo.png";
import brigade_logo from "../assets/images/realestate_images/brigade_logo.png";
import capitaland_logo from "../assets/images/realestate_images/capitaland_logo.png";
import chaitanya_logo from "../assets/images/realestate_images/chaitanya_logo.png";
import gsquare_logo from "../assets/images/realestate_images/gsquare_logo.png";
import isha_homes_logo from "../assets/images/realestate_images/isha_homes_logo.png";
import navins_logo from "../assets/images/realestate_images/navins_logo.png";
import shriram_logo from "../assets/images/realestate_images/shriram_logo.png";
import world_trade_center_logo from "../assets/images/realestate_images/world_trade_center_logo.png";

// export const GA_TRACKING_ID = 'G-LQ20YCKSZM';
export const GA_TRACKING_ID = 'G-52BD62SG78';

export const AMPERSAND_DESKTOP_VIDEO = 'https://ampersand.in/videos/ampersand_banner_desktop_video.mp4';
export const AMPERSAND_MOBILE_VIDEO = 'https://ampersand.in/videos/ampersand_banner_mobile_video.mp4';

export const MENU_ITEMS = [{
    id: 1,
    name: 'about',
    target: 'about',
}, {
    id: 2,
    name: 'work',
    target: 'work',
}, {
    id: 3,
    name: 'capabilities',
    target: 'capabilities',
}, {
    id: 4,
    name: 'culture',
    target: 'culture',
}, {
    id: 5,
    name: 'contact',
    target: 'contact',
}];

export const IDEAS_ITEMS = [{
    id: 1,
    thumbnail: work_1,
    video: 'https://ampersand.in/videos/NAC_Jewellers.mp4',
    description: "a sentimental brother-sister ad film with india's heart throb samantha for NAC jewellers",
    width: '200',
    height: '250',
    offset: '10',
}, {
    id: 2,
    thumbnail: work_2,
    video: 'https://ampersand.in/videos/Lotte.mp4',
    description: "india's most loved chocopie wishes a happy diwali, in an illustration style narrative",
    width: '200',
    height: '100',
    offset: '10',
}, {
    id: 3,
    thumbnail: work_3,
    video: 'https://ampersand.in/videos/tvs_eurogrip.mp4',
    description: "case study of how we embarked on an exciting follower increase campaign for TVS Eurogrip",
    width: '200',
    height: '100',
    offset: '10',
}, {
    id: 4,
    thumbnail: work_4,
    video: 'https://ampersand.in/videos/GRB.mp4',
    description: `the curious case study of GRB's onam campaign on instagram`,
    width: '200',
    height: '250',
    offset: '10',
}, {
    id: 5,
    thumbnail: work_5,
    video: 'https://ampersand.in/videos/TVS.mp4',
    description: "fun in every turn- a motion graphic story for the bike tyre specialist",
    width: '200',
    height: '170',
    offset: '10',
}, {
    id: 6,
    thumbnail: work_6,
    video: 'https://ampersand.in/videos/NIPPON.mp4',
    description: "unleashing walltron vetrivel with Nippon Paints",
    width: '200',
    height: '170',
    offset: '10',
}];

export const IDEAS_ITEMS_MOBILE = [{
    id: 1,
    thumbnail: work_1,
    video: 'https://ampersand.in/videos/NAC_Jewellers.mp4',
    description: "a sentimental brother-sister ad film with india's heart throb samantha for NAC jewellers",
    width: '200',
    height: '220',
    offset: '10',
}, {
    id: 2,
    thumbnail: work_2,
    video: 'https://ampersand.in/videos/Lotte.mp4',
    description: "india's most loved chocopie wishes a happy diwali, in an illustration style narrative",
    width: '200',
    height: '100',
    offset: '10',
}, {
    id: 5,
    thumbnail: work_5,
    video: 'https://ampersand.in/videos/TVS.mp4',
    description: "fun in every turn- a motion graphic story for the bike tyre specialist",
    width: '200',
    height: '170',
    offset: '10',
}, {
    id: 3,
    thumbnail: work_3,
    video: 'https://ampersand.in/videos/tvs_eurogrip.mp4',
    description: "case study of how we embarked on an exciting follower increase campaign for TVS Eurogrip",
    width: '200',
    height: '100',
    offset: '10',
}, {
    id: 4,
    thumbnail: work_4,
    video: 'https://ampersand.in/videos/GRB.mp4',
    description: "the curious case study of GRB's onam campaign on instagram",
    width: '200',
    height: '230',
    offset: '10',
}, {
    id: 6,
    thumbnail: work_6,
    video: 'https://ampersand.in/videos/NIPPON.mp4',
    description: "unleashing walltron vetrivel with Nippon Paints",
    width: '200',
    height: '170',
    offset: '10',
}];

export const BALCONY_ITEMS = [{
    id: 1,
    staticicon: threeStarStaticIcon,
    gificon: threeStarGifIcon,
    count: '18+',
    description: 'years of creating value to brands & businesses'
}, {
    id: 2,
    staticicon: peopleStaticIcon,
    gificon: peopleGifIcon,
    count: '40+',
    description: 'people with expertise in advertising & marketing'
}, {
    id: 3,
    staticicon: globeStaticIcon,
    gificon: globeGifIcon,
    count: '111+',
    description: 'brands managed nationally + globally'
}, {
    id: 1,
    staticicon: starBoxStaticIcon,
    gificon: starBoxGifIcon,
    count: '188+',
    description: 'new product launched'
}];

export const SOLUTIONS_ITEM = [{
    id: 1,
    staticicon: digitalContentStaticIcon,
    gificon: digitalContentGifIcon,
    heading: 'digital content',
    list: ['digital film production', 'content creation', 'social media management', 'performance marketing', 'amazon advertising', 'influencer management'],
}, {
    id: 2,
    staticicon: filmStaticIcon,
    gificon: filmGifIcon,
    heading: 'films',
    list: ['tvc production', 'photography', 'script writing', 'film making'],
}, {
    id: 3,
    staticicon: advertisingStaticIcon,
    gificon: advertisingGifIcon,
    heading: 'advertising',
    list: ['traditional advertising', '360 degree marketing', 'brand strategy & developement', 'new launch strategy & delivery'],
}, {
    id: 4,
    staticicon: designStaticIcon,
    gificon: designGifIcon,
    heading: 'design',
    list: ['identity creation', 'packaging design', 'brand books', 'retail displays'],
}, {
    id: 5,
    staticicon: retailStaticIcon,
    gificon: retailGifIcon,
    heading: 'retail',
    list: ['point of purchase', 'btl collaterals', 'retail innovation', 'retail displays'],
}, {
    id: 6,
    staticicon: corporateStaticIcon,
    gificon: corporateGifIcon,
    heading: 'corporate',
    list: ['corporate communication', 'internal communication', 'dealers communication', 'investor communication'],
}
];

export const MARQUEE_ITEMS = [
    "The Hindu",
    "Times of India",
    "Philips domestic appliances",
    "Preethi Kitchen Appliances",
    "Panasonic",
    "Viveks home appliances",
    "Grundfos pumps",
    "Nippon Paints",
    "Husqvarna tools",
    "Citadines",
    "Ascott",
    "Wyndam group of hotels",
    "TVS Eurogrip",
    "Maruti Suzuki",
    "Ashok Leyland",
    "Tata Motors",
    "Fleur Colonie",
    "Indica hair dye",
    "Cavin Kare",
    "Raaga haircare",
    "Indian Terrain",
    "Carl Zeiss",
    "Amazon.in",
    "Vummidi Bangaru Jewellers",
    "NAC jewellers",
    "Lactel",
    "Lavazza coffee",
    "President",
    "GRB dairy",
    "Thirumala milk",
    "Hatsun",
    "Kaleesuwari refineries",
    "Lotte chocopie",
    "Coffybite",
    "City Union Bank",
    "Citibank",
    "State bank of India",
    "Chola Insurance",
    "Akshaya homes",
    "Brigade properties",
    "Shriram properties",
    "Casagrand",
    "GSquare",
    "Navin’s housing",
    "Isha homes",
    "Akshaya homes",
    "Capital Land",
    "K7 antivirus",
    "Aircel",
    "Villgro",
    "Akshayakalpa",
    "Sadhev Ayurveda",
    "Getfit",
    "IIM Bengaluru Alumni",
    "AVT tea",
    "Banyan",
    "Chettinad Cement",
    "Danfoss",
    "ICICI home loans",
    "Indian Bank",
    "French Loaf",
    "Wangs",
    "Pepsico",
    "Taj hotels",
    "Vodafone"
];

// realestate
export const REALESTATE_BRAND_LIST = [
    { id: 1, name: "casagrand-logo", src: casagrand_logo, },
    { id: 2, name: "brigade-logo", src: brigade_logo, },
    { id: 3, name: "brigade-logo", src: shriram_logo, },
    { id: 4, name: "brigade-logo", src: gsquare_logo, },
    { id: 5, name: "brigade-logo", src: navins_logo, },
    { id: 6, name: "capitaland-logo", src: capitaland_logo, },
    { id: 7, name: "chaitanya-logo", src: chaitanya_logo, },
    { id: 8, name: "isha_homes-logo", src: isha_homes_logo, },
    { id: 9, name: "world_trade_center-logo", src: world_trade_center_logo, },
]

// packaging

