import { useEffect, useState } from "react";
import bannerImg from "../assets/images/realestate_images/banner.jpg";
import reelVideo from "../assets/videos/ampersand_realestate_showreel.mp4";
import video_thumbnail from "../assets/images/realestate_images/video_thumbnail.png";
import "../assets/css/realestate.css";
import Marquee from "react-fast-marquee";
import { REALESTATE_BRAND_LIST } from "../common/constants";
import { ContactUsPopup } from "../components/ContactUsPopup";
import { NavbarContainer } from "../components/NavbarContainer";
import { useStore } from "../store";
import { UserForm } from "../components/UserForm";

export const RealEstate = () => {

    const showOverlayVisibilityStatus = useStore((store) => store.showOverlay);
    const closeAllPopupsCB = useStore((store) => store.closeAllPopups);

    useEffect(() => {
        document.title = "Ampersand | Real Estate";

        window.addEventListener(('keydown'), (e) => {
            if (e.key === 'Escape') {
                closeAllPopupsCB();
            }
        })
    }, []);

    return (<div className="realestate-container">
        <NavbarContainer actionBtnText={'get in touch'} />

        <section className="section-container first-section">
            <div className="content">
                <p className="heading"><strong>94%</strong> of your audience<br /> miss your real estate ad</p>
                <p className="paragraph">because you launch different projects<br /> but they say the same thing and look the same way.</p>
            </div>
            <div className="banner-section">
                <img src={bannerImg} alt="" />
            </div>
        </section>

        <section className="section-container second-section">
            <div className="content">
                <p className="heading">switch to a creative<br /> hybrid agency which is <br />strategic and fresh</p>
                <p className="paragraph">ampersand has launched Million dollar worth of projects<br /> across South India for brands like Brigade, WTC, Casagrand,<br /> Cyber city, Isha Homes, Navins, Shriram Group and more.</p>
            </div>
            <div className="banner-section">
                <p className="heading">70<span>%</span></p>
                <p className="paragraph">fully sold out inventory</p>
            </div>
        </section>

        <section className="full-section-container">
            <div className="project-launch-container">
                <div className="heading-content">
                    <p className="heading heading-1">have a new project launch coming up?</p>
                    <p className="heading heading-2">leave it to us</p>
                </div>

                <div className="project-launch-holder">
                    <ul className="project-launch-item-list">
                        <li>project positioning</li>
                        <li>print advertising</li>
                        <li>tv commericals</li>
                        <li>radio ads</li>
                    </ul>

                    <ul className="project-launch-item-list">
                        <li>social media campaigns</li>
                        <li>digital lead generation</li>
                        <li>content creation</li>
                        <li>project collaterals:<br />
                            <span>brochures/ site branding/ takeaways</span>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="realestate-video-container">
            <div className="banner-video-wrapper">
                <video
                    className="banner-video"
                    width="100%"
                    height="100%"
                    // loop
                    // muted
                    controls
                    webkit-playsinline={'true'} playsInline
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                    poster={video_thumbnail}
                >
                    <source src={reelVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </section>

        <section className="realestate-who-we-are-container">
            <div className="brand-container">
                <div className="heading">brands we work for</div>
                <Marquee className="brand-list" pauseOnHover={true} autoFill={true} speed={100} loop={0} delay={0}>
                    {
                        REALESTATE_BRAND_LIST.map((item, index) => {
                            return <img key={index} src={item.src} alt={item.name} />
                        })
                    }
                </Marquee>
            </div>

            <div className="who-we-are-holder">
                <div className="content">
                    <p className="heading">who we are</p>
                    <p className="paragraph">ampersand is a hybrid agency that offers 360 degree <br />services across -both traditional and digital platforms. <br />we are a group of 30+ young turks that believes in <br />creating meaningful, relevant and strategic communication <br />for our partners, that work. </p>
                </div>
                <div className="banner-section">
                    <iframe
                        className="instagram-media instagram-media-rendered"
                        id="instagram-embed-0"
                        src="https://www.instagram.com/ampersand_communications/embed"
                        width={"100%"}
                        scrolling={"no"}
                        frameBorder={0}
                        data-instgrm-payload-id="instagram-media-payload-0"
                    ></iframe>
                </div>
            </div>
        </section>

        <ContactUsPopup />
        <UserForm />

        <div className={`popup-outer ${showOverlayVisibilityStatus ? 'pop-in' : ''}`} onClick={closeAllPopupsCB}></div>

    </div >)
}