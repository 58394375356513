import { Navbar } from "../components/Navbar";
import { Contact } from "../components/Contact";
import { Culture } from "../components/Culture";
import { Solution } from "../components/Solution";
import { About } from "../components/About";
import { Balcony } from "../components/Balcony";
import { Work } from "../components/Work";
import { Banner } from "../components/Banner";
import { Preloader } from "../components/Preloader";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export const Home = () => {
    const [isBannerVideoLoaded, setIsBannerVideoLoaded] = useState(false);
    const [isPageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: "Home Page" // document.title
        });

        const handlePageLoad = () => {
            setPageLoaded(true);
        };

        if (document.readyState === "complete") {
            handlePageLoad();
        } else {
            window.addEventListener('load', handlePageLoad);
        }

        window.addEventListener('touchforcechange', (event) => {
            var force = event.changedTouches[0].force;
            var id = event.changedTouches[0].target.id;
            var element = document.getElementById(id);

            if (element && (element.classList.contains('work-image')) && (force > 0.1)) {
                event.preventDefault();
            }
        });

        return () => {
            window.removeEventListener('load', handlePageLoad);
        };
    }, []);

    const turnOnLoaded = () => {
        setIsBannerVideoLoaded(true);
    }

    return (<div className="home-container">

        {/* ------------------ Preloader ------------------ */}
        <Preloader isBannerVideoLoaded={isBannerVideoLoaded} ispageLoaded={isPageLoaded} />

        {/* ------------------ Navbar ------------------ */}
        <section className="navbar-container">
            <Navbar />
        </section>

        {/* ------------------ Banner ------------------ */}
        <section className="banner-container">
            <Banner turnOnLoadedEvent={turnOnLoaded} ispageLoaded={isPageLoaded} />
        </section>

        {/* ------------------ Welcome ------------------ */}
        <section id="about" className="welcome-container">
            <About />
        </section>

        {/* ------------------ Balcony ------------------ */}
        <section className="balcony-container">
            <Balcony />
        </section>

        {/* ------------------ Work ------------------ */}
        <section id="work" className="good-ideas-container">
            <Work />
        </section>

        {/* ------------------ Capabilities ------------------ */}
        <section id="capabilities" className="solution-container">
            <Solution />
        </section>

        {/* ------------------ Roof ------------------ */}
        <section id="culture" className="roof-container">
            <Culture />
        </section>

        {/* ------------------ Contact ------------------ */}
        <section id="contact" className="footer-container">
            <Contact />
        </section>

    </div>)
}