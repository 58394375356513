import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Cursor } from "./components/Cursor";
import ReactGA from "react-ga4";
import { GA_TRACKING_ID } from "./common/constants";
import { RealEstate } from "./pages/RealEstate";
import { Packaging } from "./pages/Packaging";

ReactGA.initialize(GA_TRACKING_ID);

const App = () => {

  const IndexPage = () => {
    return <>
      <Home />
      {
        (window.innerWidth > 600)
          ? <Cursor />
          : ''
      }
    </>
  }


  return (<div className="App">

    <Routes>
      <Route path="/realestate" element={<RealEstate />} />
      <Route path="/packaging" element={<Packaging />} />
      <Route path="/" element={<IndexPage />} />
    </Routes>

  </div>);
}

export default App;
