import { useEffect, useRef, useState } from "react";
import { AMPERSAND_DESKTOP_VIDEO, AMPERSAND_MOBILE_VIDEO } from "../common/constants";
import "../assets/css/banner.css";

export const Banner = (props) => {

    const varstore = useRef(undefined);
    const [canPlay, setCanPlay] = useState(false);

    useEffect(() => {

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    varstore.videoRef.volume = 0.25;
                    // if (window.innerWidth > 768) {
                    varstore.videoRef.play()
                        .catch((err) => {
                            console.log('err', err);
                        });
                    // }
                } else {
                    varstore.videoRef && varstore.videoRef.pause();
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0.5, // Trigger when 50% of the element is visible
        });

        if (varstore.videoRef && props.ispageLoaded) {
            varstore.intervalId = setTimeout(() => {
                observer.observe(varstore.videoRef);
                varstore.videoRef.muted = true;
            }, 1000);

        }

        return () => {
            if (varstore.videoRef) {
                clearInterval(varstore.intervalId);
                observer.unobserve(varstore.videoRef);
            }
        };
    }, [props.ispageLoaded]);

    return (<div className="home-banner-video-wrapper">
        <video ref={elem => varstore.videoRef = elem}
            className="banner-video"
            width="100%"
            height="100%"
            loop
            controls
            autoPlay={canPlay && true && props.ispageLoaded}
            muted={varstore?.videoRef?.muted}
            webkit-playsinline={'true'} playsInline
            disablePictureInPicture
            controlsList="nodownload noplaybackrate"
            onPlaying={() => {
                // varstore.videoRef.muted = false;
                // console.log("video playing");
            }}
            onCanPlayThrough={() => {
                setCanPlay(true);
                props.turnOnLoadedEvent();
            }}
        >
            <source src={(window.innerWidth < 967) ? AMPERSAND_MOBILE_VIDEO : AMPERSAND_DESKTOP_VIDEO} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>)
}