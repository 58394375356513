import { create } from "zustand";

const store = (set) => ({

    showContactForm: false,
    showContactUsPopup: false,
    showOverlay: false,

    toggleContactFormVisibility: (status) => {
        set((store) => ({ showContactForm: status, showOverlay: status ? true : store.showOverlay }))
    },

    toggleContactUsPopupVisibility: (status) => {
        set((store) => ({ showContactUsPopup: status, showOverlay: status ? true : store.showOverlay }))
    },

    closeAllPopups: () => {
        set(() => ({
            showContactForm: false,
            showContactUsPopup: false,
            showOverlay: false
        }))
    },
});

export const useStore = create(store);